/* ========================================================================
 * Apricot's Horizontal Carousel
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/icon.scss";
import "../scss/includes/horizontal-carousel.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Horizontal Carousel
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {String} data.itemClass
 * @param {Number} data.scrollDistance
 * @param {String} data.selectedClass
 * @returns {{scrollToLeft: Function}}
 * @returns {{scrollToRight: Function}}
 * @returns {{destroy: Function}}
 *
 */
const HorizontalCarousel = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    scrollDistance: 150,
    itemClass: "cb-card",
    selectedClass: "cb-selected",
  };
  data = {
    ...defaultData,
    ...data,
  };

  const elem = data.elem;
  let nav = null;
  let navLeft = null;
  let navRight = null;
  let navContents = null;

  let scrollDistance = data.scrollDistance;
  let scrollCheck = false;

  let itemNodes = null;

  let settings = {
    transition: false,
    direction: "",
  };


  const init = () => {
    elem.horizontalCarouselPlugin = "cb";

    nav = elem.querySelector(".cb-horizontal-carousel-nav");
    navContents = elem.querySelector(".cb-horizontal-carousel-content");
    const selectedContent = navContents.querySelector("." + data.selectedClass);

    if (data.markup) {
      buildNavbar();
    } else {
      navLeft = elem.querySelector(".cb-horizontal-nav-left");
      navRight = elem.querySelector(".cb-horizontal-nav-right");
    }

    //  determine if overflow is in place
    Utils.attr(elem, "data-cb-overflow", overflowDirection());

    nav.addEventListener("scroll", addNavEvents);
    navRight.querySelector("a").addEventListener("click", addNavRightEvents);
    navLeft.querySelector("a").addEventListener("click", addNavLeftEvents);

    navContents.addEventListener("transitionend", addTransitionEvents, false);

    Utils.breakpoints();
    document.addEventListener("apricot_breakpointChange", function (e) {
      Utils.attr(elem, "data-cb-overflow", overflowDirection());
    });
    if (selectedContent && nav) {
      const navStart = nav.getBoundingClientRect().left;
      const navHalfWayPoint = navStart + nav.offsetWidth / 2;

      const selectedStart = selectedContent.getBoundingClientRect().left;
      const selectedHalfWayPoint = selectedStart + selectedContent.offsetWidth / 2;

      const scrollDestination = selectedHalfWayPoint - navHalfWayPoint;
      nav.scroll(scrollDestination, 0);
    }

    itemNodes = nav.querySelectorAll("." + data.itemClass);

    if (itemNodes.length > 0) {
      const observer = new IntersectionObserver(onIntersection, {
        root: nav,
        rootMargin: "0px",
        threshold: 1,
      });

      itemNodes.forEach((item, i) => {
        observer.observe(item);
        // GS-10326
        Utils.attr(item, 'aria-setsize', itemNodes.length);
        Utils.attr(item, 'aria-posinset', i + 1);

        //GS-10290:  move item into view on click
        item.addEventListener("click", (event) => {
          event.target.scrollIntoView({
            block: 'nearest'
          });
        });
      });
    }
  }

  const getFocusableNodes = (elem) => {
    return elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);
  };


  // callback is called on intersection change
  const onIntersection = (entries, opts) => {
    entries.forEach((entry) => {
      const focusableNodes = getFocusableNodes(entry.target);

      // add aria-hidden to cards
      if (entry.isIntersecting) {
        Utils.removeAttr(entry.target, "aria-hidden");
      } else {
        Utils.attr(entry.target, "aria-hidden", "true");
      }

      // control tab behaviour of FocusableNodes
      focusableNodes.forEach((node) => {
        if (entry.isIntersecting) {
          Utils.attr(node, "tabindex", "0");
          Utils.removeAttr(node, "aria-hidden");
        } else {
          Utils.attr(node, "tabindex", "-1");
          Utils.attr(node, "aria-hidden", "true");
        }
      });
    });
  };

  const buildNavbar = () => {
    // ------- Right
    navRight = document.createElement("DIV");
    Utils.addClass(navRight, "cb-horizontal-nav");
    Utils.addClass(navRight, "cb-horizontal-nav-right");

    const link1 = document.createElement("A");
    Utils.attr(link1, "href", "#");
    Utils.attr(link1, "tabindex", "-1");
    Utils.attr(link1, "aria-disabled", "true");
    Utils.attr(link1, "aria-label", "scroll carousel to right");

    const span1 = document.createElement("SPAN");
    Utils.addClass(span1, ["cb-icon", "cb-right"]);
    Utils.attr(span1, "aria-hidden", "true");
    link1.appendChild(span1);

    navRight.appendChild(link1);

    // ------- Left
    navLeft = document.createElement("DIV");
    Utils.addClass(navLeft, "cb-horizontal-nav");
    Utils.addClass(navLeft, "cb-horizontal-nav-left");

    const link2 = document.createElement("A");
    Utils.attr(link2, "href", "#");
    Utils.attr(link2, "tabindex", "-1");
    Utils.attr(link2, "aria-disabled", "true");
    Utils.attr(link2, "aria-label", "scroll carousel to left");

    const span2 = document.createElement("SPAN");
    Utils.addClass(span2, ["cb-icon", "cb-left"]);
    Utils.attr(span2, "aria-hidden", "true");
    link2.appendChild(span2);

    navLeft.appendChild(link2);

    elem.insertBefore(navLeft, elem.querySelector(".cb-horizontal-carousel-nav"));
    elem.appendChild(navRight);
  };

  const overflowDirection = () => {
    const containerMetrics = nav.getBoundingClientRect();
    const containerMetricsRight = Math.floor(containerMetrics.right);
    const containerMetricsLeft = Math.floor(containerMetrics.left);
    const contentMetrics = navContents.getBoundingClientRect();
    const contentMetricsRight = Math.floor(contentMetrics.right);
    const contentMetricsLeft = Math.floor(contentMetrics.left);

    if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
      a11y(navRight, true);
      a11y(navLeft, true);

      return "both";
    } else if (contentMetricsLeft < containerMetricsLeft) {
      a11y(navRight, false);
      a11y(navLeft, true);

      return "left";
    } else if (contentMetricsRight > containerMetricsRight) {
      a11y(navRight, true);
      a11y(navLeft, false);

      return "right";
    } else {
      a11y(navRight, false);
      a11y(navLeft, false);

      return "none";
    }
  };

  const adjustScroll = () => {
    Utils.attr(elem, "data-cb-overflow", overflowDirection());
  };

  const addNavEvents = (e) => {
    // scrollPosition: last_known_scroll_position
    // ticking : scrollCheck
    // scrollPosition = window.scrollY
    if (!scrollCheck) {
      window.requestAnimationFrame(() => {
        adjustScroll();
        scrollCheck = false;
      });
    }

    scrollCheck = true;
  };

  const addNavLeftEvents = (e, scrollValue) => {
    if (e) e.preventDefault();
    if (settings.transition === true) return;

    if (overflowDirection() === "left" || overflowDirection() === "both") {
      var availableScrollLeft = nav.scrollLeft;
      if (scrollValue) {
        navContents.style.transform = `translateX(${scrollValue}px)`;
      } else {
        if (availableScrollLeft < scrollDistance * 2) {
          navContents.style.transform = `translateX(${availableScrollLeft}px)`;
        } else {
          navContents.style.transform = `translateX(${scrollDistance}px)`;
        }
      }
      Utils.removeClass(navContents, "cb-no-transition");

      // Update settings
      settings.direction = "left";
      settings.transition = true;
    }

    Utils.attr(elem, "data-cb-overflow", overflowDirection());
  };

  const addNavRightEvents = (e, scrollValue) => {
    if (e) e.preventDefault();

    if (settings.transition === true) return;
    if (overflowDirection() === "right" || overflowDirection() === "both") {
      const navBarRightEdge = navContents.getBoundingClientRect().right;
      const navBarScrollerRightEdge = nav.getBoundingClientRect().right;

      var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
      if (scrollValue) {
        navContents.style.transform = `translateX(-${scrollValue}px)`;
      } else {
        if (availableScrollRight < scrollDistance * 2) {
          navContents.style.transform = `translateX(-${availableScrollRight}px)`;
        } else {
          navContents.style.transform = `translateX(-${scrollDistance}px)`;
        }
      }

      Utils.removeClass(navContents, "cb-no-transition");

      // Update settings
      settings.direction = "right";
      settings.transition = true;
    }

    Utils.attr(elem, "data-cb-overflow", overflowDirection());
  };

  const addTransitionEvents = (e) => {
    // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
    const styleOfTransform = window.getComputedStyle(navContents, null);
    const tr =
      styleOfTransform.getPropertyValue("-webkit-transform") ||
      styleOfTransform.getPropertyValue("transform");
    // If there is no transition we want to default to 0 and not null
    const amount = Math.abs(parseInt(tr.split(",")[4]) || 0);

    navContents.style.transform = "none";
    Utils.addClass(navContents, "cb-no-transition");
    // Now lets set the scroll position
    if (settings.direction === "left") {
      nav.scrollLeft = nav.scrollLeft - amount;
    } else {
      nav.scrollLeft = nav.scrollLeft + amount;
    }

    // Update settings
    settings.transition = false;
  };

  // mode: 1, show
  // mode: 0, show
  const a11y = (nav, mode) => {
    const a = nav.querySelector("a");
    if (mode) {
      Utils.attr(a, "tabindex", "0");
      Utils.attr(a, "aria-disabled", "false");
    } else {
      Utils.attr(a, "tabindex", "-1");
      Utils.attr(a, "aria-disabled", "true");
    }
  };

  const destroy = () => {
    if (elem.horizontalCarouselPlugin === "cb") {
      elem.horizontalCarouselPlugin = null;
    }

    nav.removeEventListener("scroll", addNavEvents);
    navRight.querySelector("a").removeEventListener("click", addNavRightEvents);
    navLeft.querySelector("a").removeEventListener("click", addNavLeftEvents);
    navContents.removeEventListener("transitionend", addTransitionEvents, false);

    itemNodes && itemNodes.forEach((item) => {
      observer.observe(item);
    });
  };

  if (elem.horizontalCarouselPlugin !== "cb") {
    init();
  }

  return {
    scrollToLeft: addNavLeftEvents,
    scrollToRight: addNavRightEvents,
    destroy: destroy,
  };
};

export default HorizontalCarousel;
